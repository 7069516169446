// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../../assets/stylesheets/tailwind'
import '../../assets/stylesheets/actiontext'

import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import "channels"

Rails.start()
Turbo.start()
ActiveStorage.start()

const Trix = require('trix')
require('@rails/actiontext')
Trix.config.blockAttributes.heading1.tagName = 'h3'

import 'controllers'
