import { Controller } from 'stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = [ 'panel' ]
  static classes = [ 'hidden' ]

  open(event) {
    let element = document.getElementById(event.currentTarget.dataset.id)
    element.classList.remove(this.hiddenClass)
    enter(element)
  }

  close(event) {
    let element = document.getElementById(event.currentTarget.dataset.id)
    leave(element).then(() => {
      element.classList.add(this.hiddenClass)
    })
  }

  toggle(event) {
    let element = document.getElementById(event.currentTarget.dataset.id)
    if (element.classList.contains(this.hiddenClass)) {
      element.classList.remove(this.hiddenClass)
      enter(element)
    } else {
      leave(element).then(() => {
        element.classList.add(this.hiddenClass)
      })
    }
  }
}
