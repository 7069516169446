import { Controller } from 'stimulus'

export default class extends Controller {

  static classes = [ 'hidden', 'selected', 'unselected' ]

  showElement(event){
    let elementsToHide = document.querySelectorAll('.histories')
    let elementToShow = document.getElementById(event.target.dataset.id)
    var buttonElement = event.target
    let allButtons = document.querySelectorAll('.nav-buttons')

    elementsToHide.forEach((element) => {
      element.classList.add(this.hiddenClass)
    })

    allButtons.forEach((button) => {
      button.classList.remove('bg-cbk1', 'border-cbk1')
      button.classList.add('bg-cbk2', 'border-transparent')
    })
    buttonElement.classList.remove('bg-cbk2', 'border-transparent')
    buttonElement.classList.add('bg-cbk1', 'border-cbk1')


    elementToShow.classList.remove(this.hiddenClass)
  }

  showElementMobile(event){
    let elementId = event.target.options.selectedIndex
    let elementToShow = document.getElementById(`history-mobile-${elementId}`)
    let elementsToHide = document.querySelectorAll('.histories')

    elementsToHide.forEach((element) => {
      element.classList.add(this.hiddenClass)
    })

    elementToShow.classList.remove(this.hiddenClass)
  }

}
