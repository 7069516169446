import { Controller } from 'stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = [ 'panel' ]

  initialize() {
    this.timer = null
  }

  connect() {
    this.show()
  }

  show() {
    enter(this.panelTarget).then(() => {
      this.timer = setTimeout(() => this.remove(), 4000)
    })
  }

  close() {
    clearTimeout(this.timer)
    this.remove()
  }

  remove() {
    leave(this.panelTarget).then(() => {
      this.panelTarget.remove()
    })
  }
}
